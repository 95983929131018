import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    historicalRecords: [],
    actionList: [
      {
        value: "无基本导航动作",
        icon: "noBasic",
      },
      {
        value: "左转",
        icon: "turnLeft",
      },
      {
        value: "右转",
        icon: "turnRight",
      },
      {
        value: "向左前方行驶",
        icon: "driveAheadToTheLeft",
      },
      {
        value: "向右前方行驶",
        icon: "driveAheadToTheRight",
      },
      {
        value: "向左后方行驶",
        icon: "driveToTheLeftRear",
      },

      {
        value: "向右后方行驶",
        icon: "driveToTheRightRear",
      },
      {
        value: "左转调头",
        icon: "turnLeftAndTurnAround",
      },
      {
        value: "直行",
        icon: "straightAhead",
      },
      {
        value: "靠左",
        icon: "toTheLeft",
      },
      {
        value: "靠右",
        icon: "toTheRight",
      },
      {
        value: "进入环岛",
        icon: "enteringTheRoundabout",
      },
      {
        value: "离开环岛",
        icon: "leavingTheIsland",
      },
      {
        value: "减速行驶",
        icon: "slowDownDriving",
      },
      {
        value: "到达目的地",
        icon: "destination",
      },
      {
        value: "向左前方",
        icon: "driveAheadToTheLeft",
      },
      {
        value: "向右前方",
        icon: "driveAheadToTheRight",
      },
      {
        value: "向左后方",
        icon: "driveToTheLeftRear",
      },
      {
        value: "向右后方",
        icon: "driveToTheRightRear",
      },
      {
        value: "通过人行横道",
        icon: "pedestrianCrossing",
      },
      {
        value: "通过广场",
        icon: "throughSquare",
      },
      {
        value: "通过过街天桥",
        icon: "pedestrianOverpass",
      },
      {
        value: "通过地下通道",
        icon: "underpass",
      },
      {
        value: "到道路斜对面",
        icon: "acrossRoad",
      },
    ],
    transferModel: [
      {
        value: "LEAST_TIME",
        label: "最快捷模式",
      },
      {
        value: "LEAST_FEE",
        label: "最经济模式",
      },
      {
        value: "LEAST_TRANSFER",
        label: "最少换乘模式",
      },
      {
        value: "LEAST_WALK",
        label: "最少步行模式",
      },
      {
        value: "MOST_COMFORT",
        label: "最舒适模式",
      },
      {
        value: "NO_SUBWAY",
        label: "不乘地铁模式",
      },
    ],
    transitModeList: [
      {
        icon: "gongjiao",
        value: "BUS",
      },
      {
        icon: "tielu",
        value: "SUBWAY",
      },
      {
        icon: "hangren",
        value: "WALK",
      },
      {
        icon: "tielu",
        value: "METRO_RAIL",
      },
      {
        icon: "railway",
        value: "RAILWAY",
      },
      {
        icon: "taxi",
        value: "TAXI",
      },
    ],
    commonUseList: [
      {
        name: "工作",
        id: "gz",
        address: "点击设置",
      },
      {
        name: "家庭",
        id: "jt",
        address: "点击设置",
      },
    ],
  },
  getters: {},
  mutations: {
    SetHistoricalRecords(state, list) {
      state.historicalRecords = list;
    },
    SetCommonUseList(state, list) {
      state.commonUseList = list;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          ...val,
        };
      },
    }),
  ],
});
