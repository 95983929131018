import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style/common.css"; //初始化样式
import "@/assets/style/common.scss"; //初始化样式
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
  dynamicText,
  recurrenceQuery,
  stakeToString,
  getFileType,
  debounce,
  intervalTime,
  findPath,
  setSeconds,
} from "@/utils/ruoyi";

// 全局方法挂载
app.provide("parseTime", parseTime);
app.provide("resetForm", resetForm);
app.provide("addDateRange", addDateRange);
app.provide("selectDictLabel", selectDictLabel);
app.provide("selectDictLabels", selectDictLabels);
app.provide("handleTree", handleTree);
app.provide("dynamicText", dynamicText);
app.provide("recurrenceQuery", recurrenceQuery);
app.provide("stakeToString", stakeToString);
app.provide("getFileType", getFileType);
app.provide("debounce", debounce);
app.provide("intervalTime", intervalTime);
app.provide("findPath", findPath);
app.provide("setSeconds", setSeconds);

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount("#app");
